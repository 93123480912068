import { CATEGORY_GET_ALL } from '../types';

const CategoryReducer = (state, action) => {
  // let newState = state.categories === null ? [] : state.categories.map(e => e);
  let newState = state.categories.map((e) => e);
  switch (action.type) {
    case CATEGORY_GET_ALL:
      action.payload.forEach((e) => (newState[e.id] = e));
      return { ...state, categories: newState };

    default:
      return state;
  }
};

export default CategoryReducer;
