import { USER_LOGIN, USER_LOGOUT } from '../types';

const UserReducer = (state, action) => {
  switch (action.type) {
    case USER_LOGIN:
      localStorage.setItem('isAuthenticated', true);
      localStorage.setItem('expiry', action.payload.expiry);
      return {
        ...state,
        isAuthenticated: true,
        expiry: action.payload.expiry,
      };
    case USER_LOGOUT:
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('expiry');
      return {
        isAuthenticated: false,
        expiry: null,
      };
    default:
      return state;
  }
};

export default UserReducer;
