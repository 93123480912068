import React, { createContext, useReducer } from 'react';
import CategoryReducer from './CategoryReducer';

import { getFilterUrl } from 'utils/ContextUtils';

import axios from 'axios';

import { CATEGORY_GET_ALL } from '../types';

import main from 'context/urls';

// axios config
axios.defaults.withCredentials = true;
const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

// initial state
const initialState = { categories: [], loading: false };

// create context
export const CategoryContext = createContext(initialState);

// provider component
export const CategoryProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CategoryReducer, initialState);

  // actions

  // Get all Entries

  const getAllCategories = async (params = {}, filter = {}) => {
    try {
      let plist = config;
      plist.params = params;
      const res = await axios.get(
        `${main}category/${getFilterUrl(filter)}`,
        plist
      );
      dispatch({ type: CATEGORY_GET_ALL, payload: res.data });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <CategoryContext.Provider
      value={{
        categories: state.categories,
        loading: state.loading,
        getAllCategories,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
};
