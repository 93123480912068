import React from 'react';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

import ArrowBackIosRounded from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRounded from '@material-ui/icons/ArrowForwardIosRounded';

const formatDate = (date) => {
  return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
};

const useStyles = makeStyles({
  IconButton: {
    boxShadow: '5px 5px 15px #C8D0E7, -5px -5px 20px #FFFFFF;',
    borderRadius: '12px',
  },
  WeekControl: {
    justifyContent: 'space-between',
    display: 'flex',
  },
});

const WeekControl = ({ minDate, maxDate, changeOffset }) => {
  const classes = useStyles();

  return (
    <div className={classes.WeekControl}>
      <IconButton
        className={classes.IconButton}
        onClick={() => changeOffset(-1)}
      >
        <ArrowBackIosRounded />
      </IconButton>
      <Button variant="text" onClick={() => changeOffset()}>
        {formatDate(minDate)} - {formatDate(maxDate)}
      </Button>
      <IconButton
        className={classes.IconButton}
        onClick={() => changeOffset(1)}
      >
        <ArrowForwardIosRounded />
      </IconButton>
    </div>
  );
};

export default WeekControl;
