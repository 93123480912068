import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';

import { FormGroup } from '@material-ui/core';

import { UserContext } from 'context/user/UserState';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridAutoRows: '1fr',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: 200,
  },
}));

export default function Login() {
  // own stuff

  const { loginUser } = useContext(UserContext);

  // MUI Stuff
  const classes = useStyles();
  const [values, setValues] = useState({
    username: '',
    password: '',
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const submitForm = () => {
    loginUser({ username: values.username, password: values.password });
  };

  return (
    <div className="Login">
      <div className={classes.root}>
        <FormGroup>
          <FormControl className={clsx(classes.margin, classes.textField)}>
            {/* <InputLabel htmlFor="email">Email</InputLabel> */}
            <TextField
              id="email"
              type="email"
              label="Email"
              value={values.email}
              onChange={handleChange('username')}
            />
          </FormControl>

          <FormControl
            className={clsx(classes.margin, classes.textField)}
            // variant="filled"
          >
            {/* <InputLabel htmlFor="password">Password</InputLabel> */}
            <TextField
              id="password"
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              label="Password"
              onChange={handleChange('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <FormControl
            className={clsx(classes.margin, classes.textField)}
            variant="filled"
          >
            <Button variant="contained" color="primary" onClick={submitForm}>
              Login
            </Button>
          </FormControl>
        </FormGroup>
      </div>
    </div>
  );
}
