import React, { createContext, useReducer } from 'react';
import EntryReducer from './EntryReducer';

import { getFilterUrl } from 'utils/ContextUtils';

import axios from 'axios';

import {
  ENTRY_GET,
  ENTRY_GET_ALL,
  ENTRY_CREATE,
  ENTRY_CREATE_ERROR,
  SET_MINDATE,
  SET_MAXDATE,
} from '../types';

import main from 'context/urls';

// axios config
axios.defaults.withCredentials = true;
const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

// initial state
const initialState = {
  entries: [],
  createEntryError: {},
  minDate: null,
  maxDate: null,
};

// convert object-literal to url-querystring
// const getFilterUrl = (filter = {}) => {
//   let url = '';
//   let value = null;
//   let prefix = '?';

//   for (let key of Object.keys(filter)) {
//     // converto to iso date
//     value =
//       filter[key] instanceof Date ? filter[key].toISOString() : filter[key];
//     url += `${prefix}${key}=${value}`;
//     prefix = '&';
//   }

//   return url;
// };

// create context
export const EntryContext = createContext(initialState);

// provider component
export const EntryProvider = ({ children }) => {
  const [state, dispatch] = useReducer(EntryReducer, initialState);

  // actions

  // Create Entry

  const createEntry = async (formData) => {
    try {
      const res = await axios.post(`${main}entry/`, formData, config);
      dispatch({ type: ENTRY_CREATE, payload: res.data });
      return true;
    } catch (e) {
      dispatch({ type: ENTRY_CREATE_ERROR, payload: e.response.data });
      return false;
    }
  };

  // Get all Entries

  const getAllEntries = async (params = {}, filter = {}) => {
    try {
      let plist = config;
      plist.params = params;
      const res = await axios.get(
        `${main}entry/${getFilterUrl(filter)}`,
        plist
      );
      // console.log('DONE FETCHING DATA.');
      dispatch({ type: ENTRY_GET_ALL, payload: res.data });
    } catch (e) {
      console.error(e);
    }
  };

  // Get single Entry

  const getEntry = async (id, params = {}, filter = {}) => {
    try {
      let plist = config;
      plist.params = params;
      const res = await axios.get(
        `${main}entry/${id}/${getFilterUrl(filter)}`,
        plist
      );
      dispatch({ type: ENTRY_GET, payload: res.data });
    } catch (e) {
      console.error(e);
    }
  };

  const setMinDate = (date) => {
    dispatch({ type: SET_MINDATE, payload: date });
  };

  const setMaxDate = (date) => {
    dispatch({ type: SET_MAXDATE, payload: date });
  };

  return (
    <EntryContext.Provider
      value={{
        entries: state.entries,
        createEntryError: state.createEntryError,
        createEntry,
        getEntry,
        getAllEntries,
        minDate: state.minDate,
        maxDate: state.maxDate,
        setMinDate,
        setMaxDate,
      }}
    >
      {children}
    </EntryContext.Provider>
  );
};
