import React, { useContext, useEffect } from 'react';
import { CategoryContext } from 'context/category/CategoryState';
import { makeStyles } from '@material-ui/core/styles';

import NewChart from 'components/charts/NewChart';
import EntryForm from 'components/forms/EntryForm';
import Header from 'components/layout/Header';

import { Grid, Container } from '@material-ui/core';

const useStyles = makeStyles({
  Container: {
    height: 'calc(100vh - 70px)',
    marginTop: '20px',
  },
  Grid: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
});

const Private = () => {
  const classes = useStyles();
  const { getAllCategories, categories } = useContext(CategoryContext);

  useEffect(() => {
    getAllCategories(({}, { disabled: false }));

    // eslint-disable-next-line
  }, []);

  return (
    <div className="Private">
      <Header />
      <Container className={classes.Container}>
        <Grid container spacing={10} className={classes.Grid}>
          {categories.length !== 0 ? (
            <>
              <Grid item md={3} sm={12}>
                <EntryForm />
              </Grid>
              <Grid item md={9} sm={12}>
                <NewChart categories={categories} />
              </Grid>
            </>
          ) : null}
        </Grid>
        {/* <InputTest /> */}
      </Container>
    </div>
  );
};

export default Private;
