import React, { useState } from 'react';
import 'date-fns';
import clsx from 'clsx';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TimerIcon from '@material-ui/icons/Timer';

const MuiStopwatch = ({ setTime, time, classes, hasError, error }) => {
  const [state, setState] = useState({
    startTime: 0,
    paused: true,
    tfunc: null,
  });

  const NullTime = new Date().setHours(0, 0, 0, 0);

  const startTimer = () => {
    let startTime = state.startTime;
    startTime = Date.now() - time;
    setState(() => ({ ...state, startTime }));
    let intervalF = setInterval(() => updateTime(startTime), 1000);
    setState((prevState) => ({
      ...prevState,
      tfunc: intervalF,
      paused: false,
    }));
  };

  const updateTime = (startTime) => {
    const currentTime = Date.now();
    const newTime = currentTime - startTime;
    setTime(newTime);
  };

  const pauseTimer = () => {
    clearInterval(state.tfunc);
    setState((prevState) => ({
      ...prevState,
      paused: true,
    }));
  };

  const start_stopp = () => {
    if (state.paused) {
      startTimer();
    } else {
      pauseTimer();
    }
  };

  const resetTimer = () => {
    setState((prevState) => ({
      ...prevState,
      tfunc: null,
      paused: true,
      startTime: null,
    }));
    setTime(0);
  };
  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardTimePicker
          id="time-picker"
          label="Duration"
          ampm={false}
          views={['hours', 'minutes', 'seconds']}
          format="HH:mm:ss"
          value={NullTime + time}
          onChange={(time) => {
            try {
              setTime(time - NullTime);
            } catch (e) {
              setTime(null);
            }
          }}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
          keyboardIcon={<TimerIcon />}
          disabled={state.paused === false}
          classes={{
            root: clsx(classes.formControl, classes.formControlFirst),
          }}
          error={hasError}
          helperText={error}
        />
      </MuiPickersUtilsProvider>
      <ButtonGroup
        fullWidth
        variant="text"
        className={classes.formControl}
        size="large"
      >
        <Button onClick={start_stopp}>{state.paused ? 'Start' : 'Stop'}</Button>
        <Button
          onClick={resetTimer}
          disabled={state.paused === false || time === 0} // || state.startTime == null
        >
          Reset
        </Button>
      </ButtonGroup>
    </>
  );
};

export default MuiStopwatch;
