import React from 'react';
import Login from 'components/auth/Login';

const Public = () => {
  return (
    <div className="Public">
      <Login />
    </div>
  );
};

export default Public;
