import { format } from 'date-fns';

export const options = {
  chart: {
    type: 'bar',
    // height: 350,
    width: '100%',
    stacked: true,
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 400,
        },
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0,
        },
      },
    },
  ],
  plotOptions: {
    bar: {
      // horizontal: false,
      // distributed: true,
    },
  },
  xaxis: {
    type: 'datetime',
    categories: [
      // '01/01/2011 GMT',
      // '01/02/2011 GMT',
      // '01/03/2011 GMT',
      // '01/04/2011 GMT',
      // '01/05/2011 GMT',
      // '01/06/2011 GMT'
    ],
    labels: {
      // datetimeUTC: true
      style: {
        fontFamily: 'Roboto Mono, Serif',
      },
    },
  },
  yaxis: {
    show: true,
    type: 'datetime',
    tickAmount: 9,
    labels: {
      formatter: (value) => {
        return `${(value / 60) | 0}:${(value % 60).toString().padStart(2, 0)}`;
      },
      style: {
        fontFamily: 'Roboto Mono, Serif',
      },
    },
    axisTicks: {
      show: true,
    },
  },
  legend: {
    // position: 'right',
    offsetY: 15,
    height: 35,
    position: 'bottom',
    // offsetX: 40
  },
  fill: {
    opacity: 1,
  },
  tooltip: {
    enabled: true,
    style: {
      fontFamily: 'Roboto Mono, Serif',
    },
    x: {
      show: true,
      format: 'dd MMM HH:mm',
      formatter: function (value) {
        return format(new Date(value), 'cccc, MMMM do yyyy');
      },
    },
    y: {
      // formatter: function (value) {
      //   // console.log(value);
      //   return `${Math.floor(value / 60)}:${value % 60}`;
      // },
      title: {
        formatter: (seriesName) => seriesName,
      },
    },
  },
  grid: {
    show: false,
  },
  dataLabels: {
    formatter: function (value) {
      // console.log(value);
      return `${Math.floor(value / 60)}:${Math.floor(value % 60)
        .toString()
        .padStart(2, 0)}`;
    },
    style: {
      fontFamily: 'Roboto Mono, Serif',
    },
  },
  annotations: {
    position: 'back',
    yaxis: [
      {
        y: 60,
        strokeDashArray: 0,
        borderColor: '#f2f2f2',
      },
      {
        y: 120,
        strokeDashArray: 0,
        borderColor: '#f2f2f2',
      },
      {
        y: 180,
        strokeDashArray: 0,
        borderColor: '#f2f2f2',
      },
      {
        y: 240,
        strokeDashArray: 0,
        borderColor: '#f2f2f2',
      },
      {
        y: 300,
        strokeDashArray: 0,
        borderColor: '#f2f2f2',
      },
      {
        y: 360,
        strokeDashArray: 0,
        borderColor: '#f2f2f2',
      },
      {
        y: 420,
        strokeDashArray: 0,
        borderColor: '#f2f2f2',
      },
      {
        y: 480,
        strokeDashArray: 0,
        borderColor: '#f2f2f2',
      },
      {
        y: 540,
        strokeDashArray: 0,
        borderColor: '#f2f2f2',
      },
      {
        y: 600,
        strokeDashArray: 0,
        borderColor: '#f2f2f2',
      },
      {
        y: 660,
        strokeDashArray: 0,
        borderColor: '#f2f2f2',
      },
    ],
  },
};
