export const getFilterUrl = (filter = {}) => {
  /**
   * takes object literal of url param keys and values and converts them into string of url-params that can be appended to url string
   */

  let url = '';
  let value = null;
  let prefix = '?';

  for (let key of Object.keys(filter)) {
    // conver to iso date
    value =
      filter[key] instanceof Date ? filter[key].toISOString() : filter[key];
    url += `${prefix}${key}=${value}`;
    prefix = '&';
  }

  return url;
};
