import React, { useState, useEffect, useContext } from 'react';

import { EntryContext } from 'context/entry/EntryState';
import WeekControl from 'components/charts/WeekControl';

import {
  getDateArray,
  getEmptySeries,
  getFullSeries,
  getDates,
} from 'utils/ChartUitls';

import { options as defaultOptions } from 'utils/MainChart_config';

import Chart from 'react-apexcharts';

const NewChart = ({ categories }) => {
  const {
    maxDate,
    minDate,
    entries,
    getAllEntries,
    setMinDate,
    setMaxDate,
  } = useContext(EntryContext);

  const [state, setState] = useState({
    offset: 0,
    minD: null,
    maxD: null,
  });

  const [data, setData] = useState({
    options: defaultOptions,
    series: null,
  });

  // initialize graph
  useEffect(() => {
    // console.log('useEffect (componentDidMount)');
    // console.log(state);
    changeWeekEvent();

    //eslint-disable-next-line
  }, []);

  // update observed week
  const changeWeekEvent = (delta = -state.offset) => {
    let { minD, maxD } = getDates(state.offset + delta);

    // console.log(state.offset + delta);

    setState((prevState) => ({
      ...state,
      offset: prevState.offset + delta,
      minD,
      maxD,
    }));
  };

  // conditional entry fetching
  useEffect(() => {
    // console.log('useEffect (state)');
    // console.log(state);

    // fetch new entries
    if (state.minD < minDate || state.maxD > maxDate) {
      if (state.minD < minDate || minDate === null) {
        setMinDate(state.minD);
      }
      if (state.maxD > maxDate || maxDate === null) {
        setMaxDate(state.maxD);
      }
      getAllEntries(
        {},
        {
          date__gte: state.minD,
          date__lt: state.maxD,
          category__disabled: false,
        }
      );
      // console.log('get new entries');
    } else if (state.maxD != null) {
      // only update the chart if there are valid dates
      // update chart right away
      updateChart();
    }
  }, [state]);

  // trigger chart update
  useEffect(() => {
    // console.log('useEffect (entries)');
    // console.log(entries);

    updateChart();
  }, [entries]);

  // update chart
  const updateChart = () => {
    // console.log('update chart');

    let dateArray = getDateArray(state.minD, state.maxD);

    // console.log(options.xaxis.categories);
    let series = getFullSeries(
      entries,
      getEmptySeries(7, categories),
      state.minD,
      state.maxD
    );

    setData((prevState) => ({
      options: {
        ...prevState.options,
        xaxis: {
          ...prevState.options.xaxis,
          categories: dateArray,
        },
      },
      series,
    }));
  };

  return (
    <div>
      {/* {console.log('render')} */}
      {data.options.xaxis.categories.length > 0 ? (
        <>
          <WeekControl
            minDate={state.minD}
            maxDate={state.maxD}
            changeOffset={changeWeekEvent}
          />
          {/* {console.log(data)} */}
          <Chart options={data.options} series={data.series} type="bar" />
        </>
      ) : null}
    </div>
  );
};

export default NewChart;
