// import React, { useContext } from 'react';
// import { UserContext } from 'context/user/UserState';

export const getAuthState = () => {
  let isAuthenticated = localStorage.getItem('isAuthenticated');

  if (isAuthenticated) {
    let expiry = localStorage.getItem('expiry');
    if (expiry) {
      // console.log(
      //   `Now: ${Date.now()}\nExp: ${Date.parse(expiry)}\nDif: ${Date.parse(
      //     expiry
      //   ) - Date.now()}`
      // );
      if (Date.parse(expiry) - Date.now() > 0) {
        return true;
      }
    }
  }

  localStorage.removeItem('isAuthenticated');
  localStorage.removeItem('expiry');
  return false;
};

export const getExpiryDate = () => {
  return getAuthState() ? localStorage.getItem('expiry') : null;
};

// export const LogoutAfterTimeout = props => {
//   console.log('Set Logout Timeout...');
//   const { logoutUser } = useContext(UserContext);
//   setTimeout(logoutUser(), props.timeout);

//   return null;
// };
