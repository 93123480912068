import { createMuiTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

const bg = '#E4EBF5';

const theme = createMuiTheme({
  mixins: {
    toolbar: {
      minHeight: 70,
    },
  },
  palette: {
    common: { black: '#000', white: '#fff' },
    background: { paper: bg, default: bg },
    primary: {
      light: '#7986cb',
      main: 'rgba(120, 108, 255, 1)',
      dark: 'rgba(79, 71, 180, 1)',
      contrastText: '#fff',
    },
    secondary: {
      light: 'rgba(125, 189, 216, 1)',
      main: 'rgba(85, 159, 191, 1)',
      dark: 'rgba(54, 130, 163, 1)',
      contrastText: '#fff',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },
  typography: {
    h1: {
      'font-family': ['Lato', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    },
    h2: {
      'font-family': ['Lato', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    },
    h3: {
      'font-family': ['Lato', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    },
    h4: {
      'font-family': ['Lato', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    },
    h5: {
      'font-family': ['Lato', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    },
    h6: {
      'font-family': ['Lato', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    },
  },
  paperShadows: [
    '-10.8px -10.8px 24px white inset, 12px 12px 18px #C8D0E7 inset',
    '-9.9px -9.9px 22px white inset, 11px 11px 16.5px #C8D0E7 inset',
    '-9px -9px 20px white inset, 10px 10px 15px #C8D0E7 inset',
    '-8.1px -8.1px 18px white inset, 9px 9px 13.5px #C8D0E7 inset',
    '-7.2px -7.2px 16px white inset, 8px 8px 12px #C8D0E7 inset',
    '-6.3px -6.3px 14px white inset, 7px 7px 10.5px #C8D0E7 inset',
    '-5.4px -5.4px 12px white inset, 6px 6px 9px #C8D0E7 inset',
    '-4.5px -4.5px 10px white inset, 5px 5px 7.5px #C8D0E7 inset',
    '-3.6px -3.6px 8px white inset, 4px 4px 6px #C8D0E7 inset',
    '-2.7px -2.7px 6px white inset, 3px 3px 4.5px #C8D0E7 inset',
    '-1.8px -1.8px 4px white inset, 2px 2px 3px #C8D0E7 inset',
    '-0.9px -0.9px 2px white inset, 1px 1px 1.5px #C8D0E7 inset',
    '0px 0px 0px white, 0px 0px 0px #C8D0E7',
    '-0.9px -0.9px 2px white, 1px 1px 1.5px #C8D0E7',
    '-1.8px -1.8px 4px white, 2px 2px 3px #C8D0E7',
    '-2.7px -2.7px 6px white, 3px 3px 4.5px #C8D0E7',
    '-3.6px -3.6px 8px white, 4px 4px 6px #C8D0E7',
    '-4.5px -4.5px 10px white, 5px 5px 7.5px #C8D0E7',
    '-5.4px -5.4px 12px white, 6px 6px 9px #C8D0E7',
    '-6.3px -6.3px 14px white, 7px 7px 10.5px #C8D0E7',
    '-7.2px -7.2px 16px white, 8px 8px 12px #C8D0E7',
    '-8.1px -8.1px 18px white, 9px 9px 13.5px #C8D0E7',
    '-9px -9px 20px white, 10px 10px 15px #C8D0E7',
    '-9.9px -9.9px 22px white, 11px 11px 16.5px #C8D0E7',
    '-10.8px -10.8px 24px white, 12px 12px 18px #C8D0E7',
  ],
});

theme.props = {
  MuiInput: {
    // disableUnderline: true,
    // helperText: 'crap',
    // variant: 'filled',
  },

  MuiFormControl: {
    // variant: 'filled',
  },

  MuiInputLabel: {
    // shrink: true,
  },

  MuiButton: {
    // elevation: 15,
    disableElevation: true,
  },
};

theme.overrides = {
  MuiAppBar: {
    root: {
      boxShadow: 'unset',
    },
    colorPrimary: {
      background: `linear-gradient(90deg, ${fade(
        theme.palette.primary.main,
        0.87
      )} 0%, ${theme.palette.primary.main} 15%)`,
      backgroundColor: 'transparent',
    },
  },

  MuiFormGroup: {
    root: {
      boxShadow: '5px 5px 15px #C8D0E7, -5px -5px 20px #FFFFFF;',
      borderRadius: '12px',
      padding: '20px',
    },
  },

  MuiRating: {
    iconFilled: {
      color: theme.palette.primary.main,
    },
  },
};

export default theme;
