import React, { useState, useContext } from 'react';
import clsx from 'clsx';

// other mui stuff
import { FormGroup } from '@material-ui/core';
// import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

/// date / time picker
import 'date-fns';
// import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  // KeyboardTimePicker,
  // KeyboardDateTimePicker,
  KeyboardDatePicker,
  // TimePicker
} from '@material-ui/pickers';
// button

import Button from '@material-ui/core/Button';
// import ButtonGroup from '@material-ui/core/ButtonGroup';
// import AccessTimeIcon from '@material-ui/icons/AccessTime';
// import TimerIcon from '@material-ui/icons/Timer';

// select
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

// comment
import TextField from '@material-ui/core/TextField';

// rating
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';

// stopwatch
import MuiStopwatch from 'components/forms/MuiStopwatch';

// CategoryContext
import { CategoryContext } from 'context/category/CategoryState';
import { EntryContext } from 'context/entry/EntryState';

// import formatISO from 'date-fns/formatISO';

const useStyles = makeStyles((theme) => ({
  formGroup: {
    // boxShadow: '5px 5px 15px #C8D0E7, -5px -5px 20px #FFFFFF;',
    // borderRadius: '12px',
    // padding: '20px',
    // minWidth: 250,
    // maxWidth: 250,
  },
  formControl: {
    // margin: theme.spacing(1),
    maxWidth: '100%',
    margin: '12px 0px',
  },
  formControlRating: {
    display: 'flex',
    flexDirection: 'row',
  },
  formControlFirst: {
    marginTop: '0',
  },
  formControlLast: {
    marginBottom: '0',
  },
  rating: {
    flex: 'auto',
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
}));

const EntryForm = () => {
  const classes = useStyles();
  const { categories } = useContext(CategoryContext);
  const { createEntry, createEntryError } = useContext(EntryContext);
  const [time, setTime] = useState(0);
  const [data, setData] = useState({
    category: '',
    date: new Date(),
    comment: '',
    rating: null,
  });

  const hasError = (fieldName) => {
    return createEntryError.hasOwnProperty(fieldName);
  };
  const getError = (fieldName) => {
    return hasError(fieldName) ? createEntryError[fieldName] : '';
  };

  const handleFormChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const submitForm = async (e) => {
    e.preventDefault();
    let date = data.date;
    if (data.date.getDate() === new Date().getDate()) {
      date = new Date();
    } else {
      date.setHours(0, 0, 0, 0);
    }

    let d = {
      time: Math.floor(time / 1000),
      category: data.category,
      date: date, // data.date ? date.toISOString() : null
      rating: data.rating,
      comment: data.comment,
    };

    let success = await createEntry(d);

    if (success) {
      setData({
        category: '',
        date: new Date(),
        comment: '',
        rating: null,
      });

      setTime(0);
    }
  };

  return (
    <div className="EntryForm">
      <form>
        <FormGroup className={classes.formGroup}>
          {/* Time */}

          <MuiStopwatch
            time={time}
            setTime={setTime}
            classes={classes}
            hasError={hasError('time')}
            error={getError('time')}
          />

          {/* Category selection */}

          <FormControl
            className={classes.formControl}
            error={hasError('category')}
            // variant="outlined"
          >
            <InputLabel id="demo-simple-select-label">Category</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              // label="Comment"
              id="demo-simple-select"
              value={data.category}
              label="Category"
              displayEmpty
              name="category"
              onChange={handleFormChange}
            >
              {/* <MenuItem value={null} disabled>
              Placeholder
            </MenuItem> */}
              {categories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{getError('category')}</FormHelperText>
          </FormControl>

          {/* Date-Time picker */}
          {/* <FormControl className={classes.formControl} error={hasError('date')}> */}
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              value={data.date}
              ampm={false}
              onChange={(date) =>
                setData((prevData) => ({ ...prevData, date: date }))
              }
              classes={{ root: classes.formControl }}
              label="Date and Time"
              error={hasError('date')}
              helperText={getError('date')}
              // inputVariant="outlined"
              // onError={console.log}
              minDate={new Date('2018-01-01T00:00')}
              format="dd.MM.yyyy" /* yyyy/MM/dd hh:mm a*/
            />
          </MuiPickersUtilsProvider>
          {/* <FormHelperText>{getError('date')}</FormHelperText> */}
          {/* </FormControl> */}

          {/* comment */}

          {/* <FormControl
            className={classes.formControl}
            error={hasError('comment')}
          > */}
          <TextField
            label="Comment"
            value={data.comment}
            name="comment"
            classes={{ root: classes.formControl }}
            onChange={handleFormChange}
            multiline={true}
            // variant="outlined"
            helperText={getError('comment')}
            error={hasError('comment')}
          />
          {/* <FormHelperText>{getError('comment')}</FormHelperText> */}
          {/* </FormControl> */}

          {/* Rating */}

          {/* <Rating name="rating" value={data.rating} onChange={handleFormChange} /> */}

          <FormControl
            className={clsx(classes.formControl, classes.formControlRating)}
            error={hasError('rating')}
          >
            {/* <InputLabel id="label-rating">Rating</InputLabel> */}
            <Typography
              type="label"
              color="textSecondary"
              className={classes.rating}
            >
              Rating
            </Typography>
            <Rating
              // labelId="label-rating"
              name="simple-controlled"
              // label="Rating"
              value={data.rating}
              onChange={(e, newValue) =>
                setData((prevData) => ({ ...prevData, rating: newValue }))
              }
              className={classes.rating}
            />
            <FormHelperText>{getError('rating')}</FormHelperText>
          </FormControl>

          {/* <FormControl className={classes.formControl}> */}
          <Button
            onClick={submitForm}
            variant="contained"
            color="primary"
            size="large"
            classes={{
              root: clsx(classes.formControl, classes.formControlLast),
            }}
          >
            Submit
          </Button>
          {/* </FormControl> */}
        </FormGroup>
      </form>
    </div>
  );
};

export default EntryForm;
