import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { UserContext } from 'context/user/UserState';

export default function Logout() {
  const { logoutUser, expiry } = useContext(UserContext);

  var logoutFunc;

  const autoLogout = () => {
    // Logout 2.5 seconds prior to expiry to prevent http 401 error
    logoutFunc = setTimeout(logoutUser, Date.parse(expiry) - Date.now() - 2500);
  };

  const submitForm = () => {
    clearTimeout(logoutFunc);
    logoutUser();
  };

  return (
    <>
      {autoLogout()}
      <Button color="inherit" onClick={submitForm} endIcon={<ExitToAppIcon />}>
        Logout
      </Button>
    </>
  );
}
