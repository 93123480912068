import 'styles/styles.css';

import React, { useContext } from 'react';
import Private from './Private';
import Public from './Public';

import { UserContext } from 'context/user/UserState';

import { EntryProvider } from 'context/entry/EntryState';
import { CategoryProvider } from 'context/category/CategoryState';

import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from 'theme/theme';

const get_inner_components = (loggedIn) => {
  if (loggedIn) {
    return (
      <EntryProvider>
        <CategoryProvider>
          <Private />
        </CategoryProvider>
      </EntryProvider>
    );
  } else {
    return <Public />;
  }
};

function App() {
  const user = useContext(UserContext);
  const authed = user.isAuthenticated;

  return (
    <div className="App">
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {get_inner_components(authed)}
      </MuiThemeProvider>
    </div>
  );
}

export default App;
