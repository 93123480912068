export const getDates = (offset = 0) => {
  let date = new Date();

  date.setDate(date.getDate() + 1 + offset * 7);
  let maxD = new Date(date.setHours(0, 0, 0, 0));

  date = new Date();

  date.setDate(date.getDate() - 6 + offset * 7);
  let minD = new Date(date.setHours(0, 0, 0, 0));

  return { minD, maxD };
};

export const getEmptySeries = (timespan, categories) => {
  let series = [];
  let dataArray = new Array(timespan).fill(0);

  categories.forEach((c) => {
    series[c.id] = {
      name: c.name,
      data: [...dataArray],
    };
  });

  return series;
};

export const getDateArray = (minDate, maxDate) => {
  let arr = [];
  minDate = new Date(minDate);

  while (minDate < maxDate) {
    arr.push(new Date(minDate).toISOString());
    minDate.setDate(minDate.getDate() + 1);
  }

  return arr;
};

export const getFullSeries = (entries, series, minDate, maxDate) => {
  entries.forEach((e) => {
    const index = Math.floor((e.date - minDate) / 86400000);
    series[e.category].data[index] += e.time;
  });

  series.forEach((s) => {
    s.data = s.data.map((e) => Math.floor(e / 60));
  });

  return series.filter((e) => e != null);
};
