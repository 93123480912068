// USER
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';

// ENTRIES
export const ENTRY_CREATE = 'ENTRY_CREATE';
export const ENTRY_GET = 'ENTRY_GET';
export const ENTRY_GET_ALL = 'ENTRY_GET_ALL';
export const SET_MINDATE = 'SET_MINDATE';
export const SET_MAXDATE = 'SET_MAXDATE';

// ERRORS
export const ENTRY_CREATE_ERROR = 'ENTRY_CREATE_ERROR';

// CATEGORIES
export const CATEGORY_GET_ALL = 'CATEGORY_GET_ALL';
