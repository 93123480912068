import {
  ENTRY_GET,
  ENTRY_GET_ALL,
  ENTRY_CREATE,
  ENTRY_CREATE_ERROR,
  SET_MINDATE,
  SET_MAXDATE,
} from '../types';

const EntryReducer = (state, action) => {
  // let newState = Array.from(state);
  let newState = state.entries.map((e) => e);

  switch (action.type) {
    case ENTRY_GET:
      if (action.payload) {
        action.payload.date = Date.parse(action.payload.date);
        newState[action.payload.id] = action.payload;
      }
      return { ...state, entries: newState };

    case ENTRY_GET_ALL:
      if (action.payload) {
        action.payload.forEach((element) => {
          if (element != null) {
            element.date = Date.parse(element.date);
            newState[element.id] = element;
          }
        });
      }
      // console.log('DONE UPDATING STATE');
      return { ...state, entries: newState };

    case ENTRY_CREATE:
      action.payload.date = Date.parse(action.payload.date);
      newState[action.payload.id] = action.payload;
      return { ...state, entries: newState, createEntryError: {} };

    case ENTRY_CREATE_ERROR:
      return { ...state, createEntryError: action.payload };

    case SET_MINDATE:
      return { ...state, minDate: action.payload };

    case SET_MAXDATE:
      return { ...state, maxDate: action.payload };

    default:
      return { ...state, entries: newState };
  }
};

export default EntryReducer;
