import React, { createContext, useReducer } from 'react';
import UserReducer from './UserReducer';
import axios from 'axios';

import { USER_LOGIN, USER_LOGOUT } from '../types';

import main from 'context/urls';

import { getAuthState, getExpiryDate } from 'utils/AuthUtils';

// axios config
axios.defaults.withCredentials = true;
const config = {
  headers: {
    'Content-Type': 'application/json'
  }
};

// initial state
const initialState = {
  isAuthenticated: getAuthState(),
  expiry: getExpiryDate()
};

// create context
export const UserContext = createContext(initialState);

// provider component
export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(UserReducer, initialState);

  // actions

  // Login User

  const loginUser = async formData => {
    try {
      const res = await axios.post(`${main}login/`, formData, config);
      dispatch({ type: USER_LOGIN, payload: res.data });
    } catch (e) {
      console.error(e);
    }
  };

  // Logout User

  const logoutUser = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    try {
      await axios.post(`${main}logout/`, config);
      dispatch({ type: USER_LOGOUT });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <UserContext.Provider
      value={{
        isAuthenticated: state.isAuthenticated,
        expiry: state.expiry,
        loginUser: loginUser,
        logoutUser: logoutUser
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
