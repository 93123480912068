import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import Logout from 'components/auth/Logout';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    // marginBottom: '50px',
  },
  title: {
    flexGrow: 1,
    letterSpacing: '1px',
    textShadow: '1.5px 1.5px 1.2px rgba(0,0,0,0.25)',
  },
});

export default function ButtonAppBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar className={classes.appbar} position="sticky">
        <Toolbar>
          <Typography variant="h5" component="h1" className={classes.title}>
            Chronos
          </Typography>
          <Logout />
        </Toolbar>
      </AppBar>
    </div>
  );
}
